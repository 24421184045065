export const Executivesearchtxe9 = [
    {
      Typography: <>
      Fitness & Wellness
      <span style={{ color: "#D66A3A" }}> Space Design</span>
    </>,
      colorcontent: "black",
        //   Typography1: 'Diverse experience in immigration and employment law. Key members have also served with several other expat projects in UAE in the past. Team strength comprising of Operations , HR Team, PRO, accountants and support staff. The team is equipped to extend their support throughout the country.'
    }];
  
  